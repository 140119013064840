// dateFormatter.ts
import { useFormatter } from 'next-intl';
import { getFormatter } from 'next-intl/server';

export type IntlFormatter = ReturnType<typeof useFormatter>;

// Client-side formatting functions
export function formatDateShort(
  dateInput: string | Date,
  format: IntlFormatter,
): string {
  const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;
  return format.dateTime(date, {
    timeZone: 'UTC',
    month: 'short',
    day: 'numeric',
  });
}

export function formatDateLong(
  dateInput: string | Date,
  format: IntlFormatter,
): string {
  const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;
  return format.dateTime(date, {
    timeZone: 'UTC',
    dateStyle: 'long',
  });
}

export function formatConfirmationTicketDate(
  dateInput: string | Date,
  format: IntlFormatter,
): string {
  const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;
  return format.dateTime(date, {
    timeZone: 'UTC',
    weekday: 'short',
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

// Server-side formatting functions (if needed)

export async function formatDateShortServer(
  dateInput: string | Date,
): Promise<string> {
  const format = await getFormatter();
  const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;
  return format.dateTime(date, {
    timeZone: 'UTC',
    dateStyle: 'short',
  });
}

export async function formatDateLongServer(
  dateInput: string | Date,
): Promise<string> {
  const format = await getFormatter();
  const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;
  return format.dateTime(date, {
    timeZone: 'UTC',
    dateStyle: 'long',
  });
}
