import React from 'react';
import { JSX, SVGProps } from 'react';

import { useTranslations } from 'next-intl';

import { Button } from '@repo/ui/button';

import Spinner from '../Spinner';
import { LezgoArrow } from './LezgoArrow';

interface SubmitButtonProps {
  isPlaceValid: boolean;
  isVariant?: boolean;
}

const LezgoButton = ({
  isPlaceValid,
  isVariant = false,
}: SubmitButtonProps) => {
  const t = useTranslations('Home.BookingForm');

  return (
    <Button
      type="submit"
      className={`w-full ${isVariant && `col-span-2 sm:col-span-1 sm:!h-full`} h-[69px] rounded-none bg-primary text-xl text-white sm:text-2xl`}
      disabled={!isPlaceValid}
    >
      {t('lezGo')}
      <LezgoArrow className="h-6 w-6" />
    </Button>
  );
};

export default LezgoButton;
