'use client';

import { DateDisplay } from '@/components/booking-form/DatesDisplay';
import { useDateRange } from '@/hooks/useDateRange';
import { DialogClose, DialogDescription } from '@radix-ui/react-dialog';
import { useTranslations } from 'next-intl';

import { Button } from '@repo/ui/button';
import { CalendarImproved } from '@repo/ui/calendar-improved';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@repo/ui/dialog';

interface MobileCalendarProps {
  isSelectDates?: boolean;
  nuqsShallow: boolean;
}

export default function MobileCalendar({
  isSelectDates = false,
  nuqsShallow,
}: MobileCalendarProps) {
  const t = useTranslations('Home.BookingForm');
  const { dateRange, setDateRange } = useDateRange(nuqsShallow);

  return (
    <Dialog>
      <DialogTrigger asChild>
        {isSelectDates ? (
          <Button
            variant="link"
            className="p-0 font-semibold text-purple transition-colors hover:text-primary"
          >
            {t('pickMyDates')}
          </Button>
        ) : (
          <Button
            variant={'ghost'}
            className="h-[30px] w-full justify-start border-none p-0 pl-1 text-left font-normal text-slate-500 hover:bg-transparent hover:text-accent-foreground"
          >
            <span className="text-xl font-normal sm:text-2xl">
              <DateDisplay from={dateRange.from} to={dateRange.to} />
            </span>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('pickMyDates')}</DialogTitle>
          <DialogDescription className="hidden">
            {t('pleaseSelectADateRange')}
          </DialogDescription>
        </DialogHeader>
        <CalendarImproved
          mode="range"
          selected={dateRange}
          onSelect={setDateRange}
          numberOfMonths={1}
          disabled={(date) => date <= new Date(new Date().setHours(0, 0, 0, 0))}
          defaultMonth={dateRange.from || new Date()}
        />
        <div className="w-full text-center">
          <DateDisplay from={dateRange.from} to={dateRange.to} />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button className="w-full">{t('confirmDates')}</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
