// DesktopCalendar.tsx
import { useEffect } from 'react';

import { DateDisplay } from '@/components/booking-form/DatesDisplay';
import { useDateRange } from '@/hooks/useDateRange';
import { cn } from '@/lib/utils';
import { addDays, format, isAfter, isBefore } from 'date-fns';
import { useTranslations } from 'next-intl';
import { parseAsIsoDate, useQueryState } from 'nuqs';
import { DateRange } from 'react-day-picker';

import { Button } from '@repo/ui/button';
import { Calendar } from '@repo/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@repo/ui/popover';

interface DesktopCalendarProps {
  nuqsShallow: boolean;
}

export default function DesktopCalendar({ nuqsShallow }: DesktopCalendarProps) {
  const t = useTranslations('Home.BookingForm');
  const { dateRange, setDateRange, fromDate, toDate } =
    useDateRange(nuqsShallow);

  // if( !fromDate && !toDate) {
  //   setFromDate(addDays(new Date(), 3));
  //   setToDate(addDays(new Date(), 6));
  // }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={'ghost'}
          className={cn(
            'h-[30px] w-full justify-start border-none p-0 pl-1 text-left font-normal text-slate-500 hover:bg-transparent hover:text-accent-foreground',
            !fromDate && 'text-muted-foreground',
          )}
        >
          <span className="text-xl font-normal sm:text-2xl">
            <DateDisplay from={fromDate} to={toDate} />
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="range"
          selected={dateRange}
          onSelect={setDateRange}
          numberOfMonths={2}
          disabled={(date) => date <= new Date(new Date().setHours(0, 0, 0, 0))}
        />
      </PopoverContent>
    </Popover>
  );
}
