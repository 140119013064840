'use client';

import Image from 'next/image';

import { useRouter } from '@/i18n/routing';
import { Adventure } from '@/types';

import { Button } from '@repo/ui/button';

import { LezgoArrow } from './booking-form/LezgoArrow';

function AdventureCard({
  adventure: { title, city, description, image, linkUrl, linkText },
}: {
  adventure: Adventure;
}) {
  const router = useRouter();

  const handleCityClick = () => {
    router.push(`/car-rental/${city}`);
  };

  return (
    <>
      <hr className="hidden border-black sm:block" />
      <div className="overflow-hidden">
        <div className="flex flex-col items-center justify-center gap-4 sm:flex-row">
          <div className="relative h-[150px] w-full sm:w-[320px]">
            <Image src={image} alt={title} fill className="object-cover" />
          </div>
          <div className="w-full flex-grow">
            {' '}
            {/* Added w-full for full width on mobile */}
            <div className="flex flex-col items-center justify-between gap-4 sm:flex-row">
              <div>
                <h3 className="text-xl">{title}</h3>
                <p className="mt-1 text-sm text-muted-foreground">
                  {description}
                </p>
              </div>
              <div className="flex w-full items-center justify-between sm:w-2/3 sm:justify-center">
                <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                  <div className="flex flex-wrap items-center gap-3">
                    <div className="h-[8px] w-[8px] bg-foreground"></div>
                    <div className="flex items-center">
                      <p className="link mr-2 inline-block rounded bg-secondary text-secondary-foreground hover:text-primary">
                        {linkText}
                      </p>
                    </div>
                  </div>
                </a>
                <Button
                  variant="ghost"
                  className="stroke-slate-600 p-2 sm:hidden"
                  onClick={handleCityClick}
                  aria-label="Go to city page"
                >
                  <LezgoArrow className="h-6 w-6" />
                </Button>
              </div>
              <Button
                variant="ghost"
                className="hidden stroke-slate-600 sm:flex sm:items-center"
                onClick={handleCityClick}
                aria-label="Go to city page"
              >
                <LezgoArrow className="h-8 w-8" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdventureCard;
