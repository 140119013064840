'use client';

import { useEffect } from 'react';

import { useRouter } from 'next/navigation';
import Script from 'next/script';

import { createClient } from '@/utils/supabase/client';
import posthog from 'posthog-js';

const OneTapComponent = () => {
  const supabase = createClient();
  const router = useRouter();

  const generateNonce = async (): Promise<string[]> => {
    const nonce = btoa(
      String.fromCharCode(...crypto.getRandomValues(new Uint8Array(32))),
    );
    const encoder = new TextEncoder();
    const encodedNonce = encoder.encode(nonce);
    const hashBuffer = await crypto.subtle.digest('SHA-256', encodedNonce);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashedNonce = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');

    return [nonce, hashedNonce];
  };

  useEffect(() => {
    const initializeGoogleOneTap = () => {
      window.addEventListener('load', () => {
        generateNonce().then(([nonce, hashedNonce]) => {
          google.accounts.id.initialize({
            client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID!,
            callback: (response: google.accounts.id.CredentialResponse) => {
              supabase.auth
                .signInWithIdToken({
                  provider: 'google',
                  token: response.credential,
                  nonce,
                })
                .then(({ data, error }) => {
                  if (error) throw error;
                  const user = data.user;
                  if (user?.email) {
                    posthog?.identify(user.email);
                  }

                  router.refresh();
                })
                .catch((error) => {
                  console.error('Error logging in with Google One Tap', error);
                });
            },
            nonce: hashedNonce,
            use_fedcm_for_prompt: true,
          });
          google.accounts.id.prompt();
        });
      });
    };
    initializeGoogleOneTap();
    return () => window.removeEventListener('load', initializeGoogleOneTap);
  }, [router, supabase.auth]);

  return (
    <div>
      <Script src="https://accounts.google.com/gsi/client" />
      <div id="oneTap" className="fixed right-0 top-0 z-[100]" />
    </div>
  );
};

export default OneTapComponent;
