'use client';

import React, { ReactNode, createContext, useContext, useState } from 'react';

import { Currency, CurrencyCode } from '@/types';
import { useTranslations } from 'next-intl';

interface CurrencyContextType {
  currency: Currency;
  setCurrencyCode: (code: CurrencyCode) => void;
  setExchangeRate: (code: CurrencyCode, rate: number) => void;
  getCurrencyByCode: (code: CurrencyCode) => Currency;
  getCurrencyByString: (currencyStr: string) => Currency; // New function
}

const CurrencyContext = createContext<CurrencyContextType | undefined>(
  undefined,
);

export const CurrencyProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const t = useTranslations('Currencies');

  // Helper function to get the currency name by its code
  const getCurrencyName = (code: CurrencyCode): string => {
    switch (code) {
      case CurrencyCode.USD:
        return t('usd');
      case CurrencyCode.EUR:
        return t('eur');
      case CurrencyCode.ANG:
        return t('ang');
      case CurrencyCode.CAD:
        return t('cad');
      default:
        return t('unknownCurrency');
    }
  };

  // Helper function to get the currency symbol by its code
  const getCurrencySymbol = (code: CurrencyCode): string => {
    switch (code) {
      case CurrencyCode.USD:
        return '$';
      case CurrencyCode.EUR:
        return '€';
      case CurrencyCode.ANG:
        return 'ƒ';
      case CurrencyCode.CAD:
        return 'C$';
      default:
        return '?';
    }
  };

  // This state will store exchange rates for different currencies
  const [exchangeRates, setExchangeRates] = useState<
    Record<CurrencyCode, number>
  >({
    [CurrencyCode.USD]: 1, // Default exchange rate for USD is 1
    [CurrencyCode.EUR]: 0.85, // Initial example rates
    [CurrencyCode.ANG]: 1.79,
    [CurrencyCode.CAD]: 1.25,
  });

  // Function to retrieve the full Currency object by its code
  const getCurrencyByCode = (code: CurrencyCode): Currency => {
    return {
      name: getCurrencyName(code),
      code,
      symbol: getCurrencySymbol(code),
      exchangeRate: exchangeRates[code], // Use the latest exchange rate from state
    };
  };

  // New function: Get currency by string and convert to CurrencyCode first
  const getCurrencyByString = (currencyStr: string): Currency => {
    let currencyCode: CurrencyCode;

    switch (currencyStr.toUpperCase()) {
      case 'USD':
        currencyCode = CurrencyCode.USD;
        break;
      case 'EUR':
        currencyCode = CurrencyCode.EUR;
        break;
      case 'ANG':
        currencyCode = CurrencyCode.ANG;
        break;
      case 'CAD':
        currencyCode = CurrencyCode.CAD;
        break;
      default:
        currencyCode = CurrencyCode.USD; // Default to USD if the string doesn't match
        break;
    }

    return getCurrencyByCode(currencyCode); // Use the existing getCurrencyByCode function
  };

  // State to store the current currency selected by the user
  const [currency, setCurrency] = useState<Currency>(
    getCurrencyByCode(CurrencyCode.USD),
  );

  // Function to update the current currency code
  const setCurrencyCode = (code: CurrencyCode) => {
    setCurrency(getCurrencyByCode(code)); // Set the currency object with the correct code and exchange rate
  };

  // Function to update the exchange rate for a specific currency
  const setExchangeRate = (code: CurrencyCode, rate: number) => {
    setExchangeRates((prev) => ({
      ...prev,
      [code]: rate,
    }));
    if (currency?.code === code) {
      setCurrency(getCurrencyByCode(code)); // Update the current currency if its rate is changed
    }
  };

  return (
    <CurrencyContext.Provider
      value={{
        currency,
        setCurrencyCode,
        setExchangeRate,
        getCurrencyByCode,
        getCurrencyByString,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = (): CurrencyContextType => {
  const context = useContext(CurrencyContext);
  if (context === undefined) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }
  return context;
};
