'use client';

import { useCallback } from 'react';

import MobileCalendar from '@/components/booking-form/MobileCalendar';
import { useTranslations } from 'next-intl';

import { Button } from '@repo/ui/button';

const SelectDatesBanner = () => {
  const t = useTranslations('Home.VehicleGrid');
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="flex w-full items-center gap-2 bg-sky-50 px-4 py-2 sm:w-fit">
      <span className="font-medium text-slate-500">$</span>
      <span className="font-medium text-slate-500">{t('getTheBestDeals')}</span>
      <MobileCalendar isSelectDates={true} nuqsShallow={false} />
    </div>
  );
};

export default SelectDatesBanner;
