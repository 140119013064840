import { City } from '@/types';

// Adjust the import path based on your project structure

export const airports: Record<City, google.maps.places.PlaceResult> = {
  miami: {
    name: 'Miami International Airport',
    geometry: {
      location: {
        lat: () => 25.7959,
        lng: () => -80.287,
      },
    },
    formatted_address: 'Miami International Airport',
  } as google.maps.places.PlaceResult,
  curacao: {
    name: 'Curaçao International Airport',
    geometry: {
      location: {
        lat: () => 12.1889,
        lng: () => -68.9598,
      },
    },
    formatted_address: 'Curaçao International Airport',
  } as google.maps.places.PlaceResult,
  default: {
    name: 'Curaçao International Airport',
    geometry: {
      location: {
        lat: () => 12.1889,
        lng: () => -68.9598,
      },
    },
    formatted_address: 'Curaçao International Airport',
  } as google.maps.places.PlaceResult,
};
