'use client';

import { useEffect, useState } from 'react';

import Image from 'next/image';

import { useEditBooking } from '@/app/[locale]/_providers/EditBookingContext';
import { usePlace } from '@/app/[locale]/_providers/PlaceContext';
import AutocompleteSearchBar from '@/components/booking-form/AutocompleteSearchBar';
import { useDateRange } from '@/hooks/useDateRange';
import { useRouter } from '@/i18n/routing';
import { City } from '@/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { addDays, isSameDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useTranslations } from 'next-intl';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@repo/ui/form';
import { Input } from '@repo/ui/input';

import LezgoButton from './LezgoButton';
import LezgoCalendar from './LezgoCalendar';

interface Props {
  city?: City;
  isVariant?: boolean;
  google_maps_address?: string;
  pick_up_date?: string;
  return_date?: string;
  pick_up_time?: string;
  return_time?: string;
  latitude?: number;
  longitude?: number;
  nuqsShallow?: boolean;
}

export default function BookingForm({
  city,
  isVariant = false,
  google_maps_address,
  pick_up_date,
  return_date,
  pick_up_time,
  return_time,
  latitude,
  longitude,
  nuqsShallow = false,
}: Props) {
  const t = useTranslations('Home.BookingForm');
  const router = useRouter();
  const { place, setPlace } = usePlace();
  const [isPlaceValid, setIsPlaceValid] = useState(false);
  const { isEditingBooking, closeEditBooking } = useEditBooking();
  const [dateError, setDateError] = useState<string | null>(null);
  const { fromDate, toDate } = useDateRange();
  const now = new Date();
  const threeDaysFromNow = addDays(now, 3);
  const sixDaysFromNow = addDays(now, 6);

  const BookingFormSchema = z.object({
    location: z
      .string()
      .min(2, t('errors.location.min', { count: 2 }))
      .max(100, t('errors.location.max', { count: 100 })),

    dates: z
      .object({
        from: z.date().optional(),
        to: z.date().optional(),
      })
      .refine((data) => data.from && data.to, {
        message: t('errors.dates.required'),
      })
      .refine(
        (data) => {
          if (data.from && data.to) {
            return !isSameDay(data.from, data.to);
          }
          return true;
        },
        {
          message: t('errors.dates.sameDay'),
        },
      ),

    pickUpTime: z
      .string()
      .min(1, t('errors.pickUpTime.required'))
      .max(24, t('errors.pickUpTime.max')),

    returnTime: z
      .string()
      .min(1, t('errors.returnTime.required'))
      .max(24, t('errors.returnTime.max')),
  });

  const form = useForm<z.infer<typeof BookingFormSchema>>({
    resolver: zodResolver(BookingFormSchema),
    defaultValues: {
      location: google_maps_address || '',
      dates: {
        from: fromDate || undefined,
        to: toDate || undefined,
      },
      pickUpTime: pick_up_time || '11:00',
      returnTime: return_time || '11:00',
    },
  });

  useEffect(() => {
    if (place?.geometry?.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setIsPlaceValid(!!lat && !!lng);
    } else {
      setIsPlaceValid(false);
    }
  }, [place]);

  useEffect(() => {
    if (google_maps_address && latitude && longitude) {
      const newPlace = {
        name: google_maps_address,
        geometry: {
          location: { lat: () => latitude, lng: () => longitude },
        },
        formatted_address: google_maps_address,
      } as google.maps.places.PlaceResult;
      setPlace(newPlace);
      setIsPlaceValid(true);
      form.setValue('location', google_maps_address);
    }
  }, [google_maps_address, latitude, longitude, setPlace, form]);

  useEffect(() => {
    const subscription = form.watch((values) => {
      if (values.location === '') {
        setIsPlaceValid(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [form]);

  function onSubmit(values: z.infer<typeof BookingFormSchema>) {
    if (!isPlaceValid) {
      form.setError('location', {
        type: 'manual',
        message: 'Please select a valid location from the suggestions.',
      });
      return;
    }

    let pick_up_date: Date | null;
    let return_date: Date | null;

    if (!fromDate || !toDate) {
      pick_up_date = threeDaysFromNow;
      return_date = sixDaysFromNow;
    } else {
      pick_up_date = fromDate;
      return_date = toDate;
    }

    const formattedPickUpDate = formatInTimeZone(
      pick_up_date,
      'UTC',
      'yyyy-MM-dd',
    );
    const formattedReturnDate = formatInTimeZone(
      return_date,
      'UTC',
      'yyyy-MM-dd',
    );

    // Build the query parameters as an object
    const query: Record<string, string> = {
      google_maps_address: place?.name || '',
      pick_up_date: formattedPickUpDate,
      return_date: formattedReturnDate,
      pick_up_time: values.pickUpTime,
      return_time: values.returnTime,
    };

    if (place?.geometry?.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      if (lat && lng) {
        const coordinates = `${lat},${lng}`;
        query.coordinates = coordinates;
      }
    }

    // Create a query string using URLSearchParams
    const searchParams = new URLSearchParams(query);

    // Construct the relative URL
    const url = `/car-picker?${searchParams.toString()}`;

    // Use router.push with the relative URL
    router.push(url);

    closeEditBooking();
  }

  return (
    <div
      className={`w-full ${isVariant ? 'z-10 bg-white' : `${isEditingBooking ? 'sm:mt-2' : 'max-w-[600px]'} z-20 border border-slate-100 bg-white sm:mb-4`}`}
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
          <div>
            <FormField
              control={form.control}
              name="location"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-0 border-b-2 border-slate-100 p-3">
                  <FormLabel className="text-sm font-semibold tracking-wide text-slate-500">
                    {t('whereTo').toUpperCase()}
                  </FormLabel>
                  <FormControl>
                    <div className="relative">
                      <AutocompleteSearchBar
                        onChange={(value) => {
                          field.onChange(value);
                          form.clearErrors('location');
                        }}
                        city={city}
                        initialValue={google_maps_address}
                      />
                      <Image
                        src="/images/lezgo/lezgo-blocks.svg"
                        alt="Blocks"
                        width={40}
                        height={40}
                        className={`absolute bottom-[33px] right-[-20px] ${isEditingBooking ? 'sm:bottom-[-40px] sm:right-[-20px]' : 'sm:bottom-[-40px] sm:right-[-40px]'} mb-2 mr-2`}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <LezgoCalendar nuqsShallow={nuqsShallow} dateError={dateError} />

          <div
            className={`grid ${isVariant ? 'grid-cols-2 sm:grid-cols-3' : 'grid-cols-2'}`}
          >
            <FormField
              control={form.control}
              name="pickUpTime"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-1 space-y-0 border-b-2 border-r-2 border-slate-100 p-3">
                  <FormLabel className="text-sm font-semibold text-slate-500">
                    {t('pickUpTime').toUpperCase()}
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="time"
                      className="border-none px-0 text-xl font-semibold text-slate-500 shadow-none hover:cursor-text hover:text-accent-foreground focus-visible:text-accent focus-visible:outline-none focus-visible:ring-0 sm:!text-2xl [&::-webkit-calendar-picker-indicator]:hover:cursor-pointer"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="returnTime"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-1 space-y-0 border-b-2 border-slate-100 p-3">
                  <FormLabel className="text-sm font-semibold text-slate-500">
                    {t('returnTime').toUpperCase()}
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="time"
                      className="border-none px-0 text-xl font-semibold text-slate-500 shadow-none hover:cursor-text hover:text-accent-foreground focus-visible:text-accent focus-visible:outline-none focus-visible:ring-0 sm:!text-2xl [&::-webkit-calendar-picker-indicator]:hover:cursor-pointer"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {isVariant && (
              <LezgoButton isPlaceValid={isPlaceValid} isVariant={true} />
            )}
          </div>

          {!isVariant && <LezgoButton isPlaceValid={isPlaceValid} />}
        </form>
      </Form>
    </div>
  );
}
