import { isAfter, isBefore } from 'date-fns';
import { parseAsIsoDate, useQueryState } from 'nuqs';
import { DateRange } from 'react-day-picker';

export function useDateRange(shallow = false) {
  const [fromDate, setFromDate] = useQueryState(
    'pick_up_date',
    parseAsIsoDate.withOptions({ shallow }),
  );
  const [toDate, setToDate] = useQueryState(
    'return_date',
    parseAsIsoDate.withOptions({ shallow }),
  );

  const dateRange: DateRange = {
    from: fromDate || undefined,
    to: toDate || undefined,
  };

  const setDateRange = (newValue: DateRange | undefined) => {
    if (fromDate && toDate && newValue?.to && isAfter(newValue.to, toDate)) {
      setFromDate(newValue.to);
      setToDate(null);
    } else if (
      fromDate &&
      toDate &&
      newValue?.to &&
      newValue?.from &&
      isBefore(newValue.from, fromDate)
    ) {
      setFromDate(newValue.from);
      setToDate(null);
    } else if (newValue?.from) {
      setFromDate(newValue.from);
      setToDate(newValue.to || null);
    } else {
      setFromDate(null);
      setToDate(null);
    }
  };

  return { dateRange, setDateRange, fromDate, toDate, setFromDate, setToDate };
}
