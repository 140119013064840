import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { City } from '@/types';
import { airports } from '@/utils/airports';
import { Library } from '@googlemaps/js-api-loader';
import { useJsApiLoader } from '@react-google-maps/api';
import { useTranslations } from 'next-intl';

import { usePlace } from '../../app/[locale]/_providers/PlaceContext';

const libs: Library[] = ['core', 'maps', 'places', 'marker'];

interface AutocompleteSearchBarProps {
  onChange: (value: string) => void;
  city?: City;
  initialValue?: string;
}

const getBoundsForCity = (city: City) => {
  switch (city) {
    case 'miami':
      return new google.maps.LatLngBounds(
        new google.maps.LatLng(25.709, -80.459),
        new google.maps.LatLng(25.909, -80.139),
      );
    case 'curacao':
    default:
      return new google.maps.LatLngBounds(
        new google.maps.LatLng(12.044, -69.11),
        new google.maps.LatLng(12.354, -68.75),
      );
  }
};

const getAirportInfo = (city: City) => airports[city];

export default function AutocompleteSearchBar({
  onChange,
  city = 'default',
  initialValue,
}: AutocompleteSearchBarProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const { setPlace } = usePlace();
  const t = useTranslations('Home.BookingForm');
  const [error, setError] = useState<string | null>(null);

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY!,
    libraries: libs,
    version: 'beta',
  });

  const [value, setValue] = useState(() => {
    if (initialValue) return initialValue;
    const airportInfo = getAirportInfo(city);
    return airportInfo.formatted_address || '';
  });

  const handlePlaceSelect = useCallback(() => {
    const place = autocompleteRef.current?.getPlace();
    if (place) {
      setPlace(place);
      if (inputRef.current) {
        const selectedValue = inputRef.current.value;
        setValue(selectedValue);
        onChange(selectedValue);
      }
    }
  }, [setPlace]);

  useEffect(() => {
    if (!initialValue) {
      const airportInfo = getAirportInfo(city);
      setPlace(airportInfo);
      onChange(airportInfo.formatted_address || '');
    }
  }, [initialValue, city, setPlace]);

  useEffect(() => {
    if (loadError) {
      setError('Failed to load Google Maps API');
      return;
    }

    if (isLoaded && inputRef.current && !autocompleteRef.current) {
      const bounds = getBoundsForCity(city);
      const options: google.maps.places.AutocompleteOptions = {
        bounds,
        componentRestrictions: { country: city === 'miami' ? 'us' : 'cw' },
      };

      autocompleteRef.current = new google.maps.places.Autocomplete(
        inputRef.current,
        options,
      );
      autocompleteRef.current.addListener('place_changed', handlePlaceSelect);
    }

    return () => {
      if (autocompleteRef.current) {
        google.maps.event.clearInstanceListeners(autocompleteRef.current);
      }
    };
  }, [isLoaded, loadError, city, handlePlaceSelect]);

  useEffect(() => {
    if (autocompleteRef.current) {
      const bounds = getBoundsForCity(city);
      autocompleteRef.current.setBounds(bounds);
      autocompleteRef.current.setComponentRestrictions({
        country: city === 'miami' ? 'us' : 'cw',
      });
    }
  }, [city]);

  useEffect(() => {
    if (value === '') {
      setPlace(null);
    }
  }, [value, setPlace]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex flex-col space-y-4">
      <input
        ref={inputRef}
        id="autocomplete-input"
        placeholder={t('enterALocation')}
        type="text"
        value={value}
        onChange={handleInputChange}
        disabled={!isLoaded}
        className="transition-color flex w-full border-none bg-transparent !text-xl font-semibold text-slate-500 placeholder:text-slate-500 hover:text-accent-foreground hover:placeholder:text-accent focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 sm:!text-2xl"
        aria-label={t('enterALocation')}
      />
    </div>
  );
}
