// LezgoCalendar.tsx
import { useEffect } from 'react';

import DesktopCalendar from '@/components/booking-form/DesktopCalendar';
import MobileCalendar from '@/components/booking-form/MobileCalendar';
import { useDateRange } from '@/hooks/useDateRange';
import useMediaQuery from '@/lib/hooks/useMediaQuery';
import { addDays } from 'date-fns';
import { useTranslations } from 'next-intl';
import { useFormContext } from 'react-hook-form';

import { FormField, FormItem, FormLabel, FormMessage } from '@repo/ui/form';

interface LezgoCalendarProps {
  dateError: string | null;
  nuqsShallow: boolean;
}

export default function LezgoCalendar({
  dateError,
  nuqsShallow,
}: LezgoCalendarProps) {
  const t = useTranslations('Home.BookingForm');
  const isMobile = useMediaQuery('(max-width: 640px)');
  const { control, setValue } = useFormContext();
  const { dateRange } = useDateRange();
  const now = new Date();
  const threeDaysFromNow = addDays(now, 3);
  const sixDaysFromNow = addDays(now, 6);

  // Synchronize dateRange with form's dates field
  useEffect(() => {
    setValue('dates', dateRange);
    if (!dateRange.from && !dateRange.to) {
      setValue('dates', {
        from: threeDaysFromNow,
        to: sixDaysFromNow,
      });
    }
  }, [dateRange, setValue, threeDaysFromNow, sixDaysFromNow]);

  return (
    <div>
      <FormField
        control={control}
        name="dates"
        render={({ field }) => (
          <>
            <FormItem className="flex flex-col gap-1 space-y-0 border-b-2 border-slate-100 p-3 pb-2 pl-2">
              <FormLabel className="pl-1 text-sm font-semibold text-slate-500">
                {t('when').toUpperCase()}
              </FormLabel>
              {isMobile ? (
                <MobileCalendar nuqsShallow={nuqsShallow} />
              ) : (
                <DesktopCalendar nuqsShallow={nuqsShallow} />
              )}
              {/* Display error message if dateError is present */}
              <FormMessage className="pl-1" />
            </FormItem>
          </>
        )}
      />
    </div>
  );
}
