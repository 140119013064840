'use client';

import React from 'react';

import { useCurrency } from '@/app/[locale]/_providers/CurrencyContext';
import { convertAmount } from '@/utils/convertAmount';
import { useTranslations } from 'next-intl';

import { VehicleClassWithDetails } from '@repo/db';

interface Props {
  vehicle: VehicleClassWithDetails;
}

export default function VehiclePrice({ vehicle }: Props) {
  const { currency } = useCurrency();
  const t = useTranslations('VehiclePicker.VehicleCard');

  const basePriceInUSD = vehicle.PriceHistory[0].basePrice;
  const convertedAmount = convertAmount(Number(basePriceInUSD), currency!);

  return (
    <h2 className="text-start font-lota text-xl">
      {currency?.symbol}
      {convertedAmount}
      <span className="font-lota text-slate-400">/{t('day')}</span>
    </h2>
  );
}
